import fm from 'format-message';
import textStyles from 'config/branding/textStyles';
import media from '@activebrands/core-web/config/media';
import overlay from '@activebrands/core-web/libs/overlay';
import { styled, useStyletron } from '@activebrands/core-web/libs/styletron';
import StateIcon from 'components/icons/StateIcon';
import Heading from 'components/text/Heading';
import Paragraph from 'components/text/Paragraph';

const Wrapper = styled('div', {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    zIndex: 'calc(var(--zindex-header)',
    width: '328px',
    backgroundColor: 'var(--color-bg-password-overlay)',
    borderRadius: '2px',
    padding: '16px',

    [media.min['mobile.lg']]: {
        minWidth: '352px',
    },
});

const CloseButton = styled('button', {
    display: 'flex',
    alignItems: 'center',
    width: '40px',
    height: '100%',
    cursor: 'pointer',
    justifyContent: 'flex-end',
});

const NotWithinTeamOrderingPeriodOverlay = () => {
    const [css] = useStyletron();

    return (
        <Wrapper>
            <div
                className={css({
                    display: 'flex',
                    width: '100%',
                    justifyContent: 'space-between',
                    marginBottom: '16px',
                })}
            >
                <Heading>
                    <span style={textStyles['Primary/24_120_-4']}>{fm('OBS!')}</span>
                </Heading>

                <CloseButton type="button" onClick={() => overlay.close('not-within-team-ordering-period')}>
                    <StateIcon size="16px" state="reset" />
                </CloseButton>
            </div>

            <Paragraph $style={{ marginBottom: '24px' }} fontKeys="Primary/18_100_-3">
                {fm(
                    'Team Store is not yet open for placing orders. Please refer to the opening dates specified on the Team Store page.'
                )}
            </Paragraph>
        </Wrapper>
    );
};

export default NotWithinTeamOrderingPeriodOverlay;
